export default [
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: '',
    type: '',
    techInfos: [''],
    imageUrl: '',
    smallImageUrl: '',
    url: '',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
  {
    title: 'Skripsi SIG ',
    type: 'WEB',
    techInfos: ['HTML, CSS, PHP, MySQL'],
    imageUrl: '/images/projects/sig.jpg',
    smallImageUrl: '/images/projects/small/sig.jpg',
    url: '#',
    bgColor: '#111111',
    blendMode: 'lighten',
  },
];
